import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const AdminLoginRoute = ({ authenticated, admin }) => {
  console.log(authenticated,admin)
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return !authenticated || !admin ?  <Outlet /> : <Navigate to="/admin" replace/>;
}

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
  admin: state.user.admin
});

AdminLoginRoute.propTypes = {
  user: PropTypes.object
};

export default connect(mapStateToProps)(AdminLoginRoute);