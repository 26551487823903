import {
  LIKE_MILESTONE, LOADING_USER, SET_ADMIN, SET_AUTHENTICATED,
  SET_CURRENT_USER, SET_UNAUTHENTICATED, SET_USER, UNLIKE_MILESTONE,
  VOTE_POLL,SET_UNADMIN
} from '../types';

const initialState = {
  authenticated: null,
  admin: false,
  currentUser: null,
  credentials : {},
  loading: false
};

export default function(state=initialState,action){
  switch(action.type){
   case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload || null
      }
    case SET_UNAUTHENTICATED:
      return {
        ...initialState,
        authenticated: false
      }
        
    case SET_ADMIN:
      return {
        ...state,
        admin: true
      };

      case SET_UNADMIN:
      return {
        ...state,
        admin: false
      };
    case SET_USER:
        return {
          ...state,
          authenticated: true,
          loading: false,
          ...action.payload
        };
    case LOADING_USER:
      return {
        ...state,
        loading: true
      };
    case LIKE_MILESTONE:
      return {
        ...state,
        likes: [
          ...state.likes,
          {
          userUsername: state.credentials.username,
          milestoneId: action.payload.milestoneId
          }
        ]
      };
    
    default:
      return state;
  }
}