import { Image } from "react-grid-gallery";



export interface CustomImage extends Image {
  original: string;
}

export const images: CustomImage[] = [
  {
    src: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill2.jpg?alt=media&token=0e217408-d636-4455-b889-d48bf65b4c5b",
    original: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill2.jpg?alt=media&token=0e217408-d636-4455-b889-d48bf65b4c5b",
    width: 141*3,
    height: 193*3,
    caption: "Chris Nicholls",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill3.jpg?alt=media&token=70e95455-716f-4344-9a28-d61906fec0c6",
    original: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill3.jpg?alt=media&token=70e95455-716f-4344-9a28-d61906fec0c6",
    width: 141*3,
    height: 193*3,
    caption: "Chris Nicholls",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill5.jpg?alt=media&token=27e81fbc-1f39-4f6a-a682-7855d6bb4764",
    original: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill5.jpg?alt=media&token=27e81fbc-1f39-4f6a-a682-7855d6bb4764",
    width: 300*3,
    height: 193*3,
    caption: "Collin Gaudet",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill4.jpg?alt=media&token=37bc0bcb-3d73-4523-91b0-892c0750db89",
    original: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill4.jpg?alt=media&token=37bc0bcb-3d73-4523-91b0-892c0750db89",
    width: 141*3,
    height: 193*3,
    caption: "Collin Gaudet",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill6.jpg?alt=media&token=5e367058-1fb8-45b5-af0f-45bee7854ee4",
    original: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill6.jpg?alt=media&token=5e367058-1fb8-45b5-af0f-45bee7854ee4",
    width: 141*3,
    height: 193*3,
    caption: "David Hou",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill7.jpg?alt=media&token=a9b6297f-9674-4bca-851e-cc488b543a78",
    original: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill7.jpg?alt=media&token=a9b6297f-9674-4bca-851e-cc488b543a78",
    width: 300*3,
    height: 193*3,
    caption: "David Hou",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill8.jpg?alt=media&token=ef3ce879-d332-4734-b0b5-9de6cc206912",
    original: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill8.jpg?alt=media&token=ef3ce879-d332-4734-b0b5-9de6cc206912",
    width: 141*3,
    height: 193*3,
    caption: "David Hou",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill10.jpg?alt=media&token=3e9482ae-98b3-40c0-9a72-64bca9d0411b",
    original: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill10.jpg?alt=media&token=3e9482ae-98b3-40c0-9a72-64bca9d0411b",
    width: 141*3,
    height: 193*3,
    caption: "David Hou",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill15.jpg?alt=media&token=454939a9-f043-4259-ae68-97edce6759b5",
    original: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill15.jpg?alt=media&token=454939a9-f043-4259-ae68-97edce6759b5",
    width: 300*3,
    height: 193*3,
    caption: "David Hou",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill11.jpg?alt=media&token=81a45160-160d-42fe-8e88-bcb2be6fb738",
    original: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill11.jpg?alt=media&token=81a45160-160d-42fe-8e88-bcb2be6fb738",
    width: 141*3,
    height: 193*3,
    caption: "David Hou",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill12.jpg?alt=media&token=a81c039a-890c-425e-bb4e-a2875b6d15da",
    original: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill12.jpg?alt=media&token=a81c039a-890c-425e-bb4e-a2875b6d15da",
    width: 141*3,
    height: 193*3,
    caption: "David Hou",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill13.jpg?alt=media&token=33577bc0-7508-4f74-b04a-91ce3a3714be",
    original: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill13.jpg?alt=media&token=33577bc0-7508-4f74-b04a-91ce3a3714be",
    width: 141*3,
    height: 193*3,
    caption: "David Hou",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill14.jpg?alt=media&token=9c429749-85a4-401b-a361-928058db62dc",
    original: "https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill14.jpg?alt=media&token=9c429749-85a4-401b-a361-928058db62dc",
    width: 141*3,
    height: 193*3,
    caption: "David Hou",
  },
 
  
];
