import PropTypes from "prop-types";
import { Component } from "react";
import { Link } from "react-router-dom";

// Images


// REDUX Stuff
import { connect } from "react-redux";
import { loginUser } from "../redux/actions/userActions";

// My Components
import ResponsiveAppBar from '../components/Appbar';

import '../css/login.css'
import theme from '../utils/theme';
import { createTheme, ThemeProvider } from  "@mui/material/styles";
import Footer from '../components/Footer';



// MUI Stuff
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";


const themeProvider = createTheme(theme);

export class login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      showPassword: false,
      staySignedIn: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }
  handleSubmit = (event) => {
    event.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password,
      persistence: this.state.staySignedIn,
    };
    this.props.loginUser(userData, this.props.history);
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleShowPassword = (event) => {
    this.setState({ showPassword: event.target.checked });
  };

  handleStaySignedIn = (event) => {
    this.setState({ staySignedIn: event.target.checked });
  };

  handleSendVerificationLink = () => {
    this.props.sendVerificationLink(this.state.email);
  };

  render() {
    const {
      UI: { loading, message },
    } = this.props;
    const { errors } = this.state;

    return (
      <div >
          <ResponsiveAppBar/>
        <ThemeProvider theme={themeProvider}>
      <div className="login-container">
        
        
          
          <h1 className="">Admin Login</h1>
          <form noValidate onSubmit={this.handleSubmit} className="">
            <TextField
              id="email"
              name="email"
              type="email"
              label="Email"
              className="textfield"
              helperText={errors.email}
              error={errors.email ? true : false}
              value={this.state.email}
              onChange={this.handleChange}
              variant="outlined"
              fullWidth
            />
            <TextField
              id="password"
              name="password"
              type={this.state.showPassword ? "text" : "password"}
              label="Password"
              className="textfield"
              helperText={errors.password}
              error={errors.password ? true : false}
              value={this.state.password}
              onChange={this.handleChange}
              variant="outlined"
              fullWidth
            />

          <div className="">
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.showPassword}
                  onChange={this.handleShowPassword}
                />
              }
              label="Show Password"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.staySignedIn}
                  onChange={this.handleStaySignedIn}
                />
              }
              label="Stay Signed In"
            />
</div>
            {errors.general && (
              <Typography variant="body2" className="">
                {errors.general}
              </Typography>
            )}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className=""
              disableElevation
              disabled={loading}
            >
              Login
              {loading && (
                <CircularProgress size={30} className=""/>
              )}
            </Button>
          </form>
        
      </div>
      <Footer/>
      </ThemeProvider>
      </div>
      
    );
  }
}

login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  loginUser,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(login);
