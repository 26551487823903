import { useLocation, Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Home from "./pages/home";
import Contact from "./pages/contact";
import Milestones from "./pages/milestones";
import Milestone from "./pages/milestone";
import Videos from "./pages/videos";
import About from "./pages/about";
import Admin from './pages/admin';
import Login from './pages/login';
import Privacy from './pages/privacy'
import Terms from './pages/terms'
import AdminPrivateRoute from './utils/AdminPrivateRoute'
import AdminLoginRoute from './utils/AdminLoginRoute'

// Redux 
import { connect } from "react-redux";
import { fetchUser } from './redux/actions/userActions'

const AnimatedRoutes = () => {

  const location = useLocation();

  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        <Route exact path="/" element={<Home  />} />
        <Route exact path="milestones" element={<Milestones />} />
        <Route exact path="milestones/:slug" element={<Milestone />} />
        <Route exact path="videos" element={<Videos />} />
        <Route exact path="contact" element={<Contact  />} />
        <Route exact path="about" element={<About  />} />
        <Route exact path="privacy" element={<Privacy  />} />
        <Route exact path="terms" element={<Terms  />} />
        <Route exact path='/admin' element={<AdminPrivateRoute/>}>
            <Route exact path ="/admin" element={<Admin />} />
          </Route>
          <Route exact path='/adminlogin' element={<AdminLoginRoute/>}>
            <Route exact path ="/adminlogin" element={<Login />} />
          </Route>
          <Route exact path ="*" element={<Home />} />
      </Routes>
    </AnimatePresence>
  );
};

export default connect(null, { fetchUser })(AnimatedRoutes);
