import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import AppBar from '@mui/material/AppBar';
import NavDrawer from './NavDrawer';

import ScrollToColor from './AppBarContainer'

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';


import instagram from '../media/icons/instagram_bw.png'
import tiktok from '../media/icons/tiktok_bw.png'
import youtube from '../media/icons/youtube_bw.png'
import twitch from '../media/icons/twitch_bw.png'

import logo from '../media/WillNewLogo.png'

import Slide from '@mui/material/Slide';

import { Typography } from '@mui/material';
import theme from '../utils/theme';
import { createTheme, ThemeProvider } from  "@mui/material/styles";
import { useSelector } from 'react-redux';

const themeProvider = createTheme(theme);

  

class ResponsiveAppBar extends Component {

    
    state = {
        open: false,
        socialMenuOpen: false,
        socialAnchorEl: null
      };
    
       handleOpenNavMenu = (event) => {
        this.setState({open:true});
      };
       handleOpenSocialsMenu = (event) => {
        this.setState({socialAnchorEl:event.currentTarget});
      };
    
       handleCloseNavMenu = () => {
        this.setState({open:false});
      };
    
       handleCloseSocialsMenu = () => {
        this.setState({socialAnchorEl:null});
      };


 
render(){

  return (
    <ThemeProvider theme={themeProvider}>
           
    <div className='home-transition'>
    <AppBar position="fixed" style={{ background: '#fff' }}elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
         
         {/* MOBILE */}

        {/* <Box  sx={{ flexGrow: 0, display: { xs: 'flex', md:'none'}, opacity:0}}>
           <NavDrawer/>
          </Box> */}
          
          <Box sx={{ justifyContent: 'left', marginLeft:"0px", flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>

          <Button
          component={Link}
          to="/"
          sx={{
            maxWidth:"100px",
            textTransform:'none'
          }}>
              <Box
            component="img"
            sx={{
            width: "100%",
            m:1
            }}
            src={logo} 
        />
          </Button>

          </Box>

        <Box  sx={{ flexGrow: 0, display: { xs: 'flex', md:'none'} }}>
           <NavDrawer/>
          </Box>






        {/* DESKTOP */}

        
        
        <Box sx={{ width:"33.33%",flexGrow: 1,  gap:"20px", justifyContent: "center",  display: { xs: 'none', md: 'none' }}}>
        <Button
        component={Link}
        to='/milestones'
        sx={{mr:'0px'}}>
          <Typography className='nav-text'
          sx={{fontWeight: "500", fontSize: 18, textTransform:'uppercase'}}>Milestones</Typography>
        </Button>

        <Button
        component={Link}
        to='/videos'
        sx={{mr:'0px'}}>
          <Typography className='nav-text'
          sx={{fontWeight: "500", fontSize: 18, textTransform:'uppercase'}}>Videos</Typography>
        </Button>

        {/* <Button
        component="a" href="https://shopwilliamwhite.com/"
        target="_blank"
        sx={{mr:'0px'}}>
          <Typography className='nav-text'
          sx={{fontWeight: "500", fontSize: 18, textTransform:'uppercase'}}>Shop</Typography>
        </Button> */}
        
        <Button
        component={Link}
        to='/contact'
        sx={{mr:'0px'}}>
          <Typography className='nav-text'
          sx={{fontWeight: "500", fontSize: 18, textTransform:'uppercase'}}>Contact</Typography>
        </Button>

        <Button
        component={Link}
        to='/about'
        sx={{mr:'0px'}}>
          <Typography className='nav-text'
          sx={{fontWeight: "500", fontSize: 18, textTransform:'uppercase'}}>About</Typography>
        </Button>


        </Box>


        <Box sx={{width:"33.33%", flexGrow: 1,  gap:"30px", justifyContent: "flex-start", display: { xs: 'none', md: 'flex' } }}>

<Button
        component="a" href="https://www.instagram.com/williamwhite18/"
        target="_blank"
       sx={{mr:'0px'}}>
<div className='side-by-side'>

               <img src={instagram}/>
               
         </div>
       </Button>

       <Button
       component="a" href="https://www.tiktok.com/@whiteyy18?"
       target="_blank"
       sx={{mr:'0px'}}>
           <div className='side-by-side'>
           
               <img src={tiktok}/>
               
         </div>
       </Button>

       <Button
        component="a" href="https://www.youtube.com/channel/UCVJvvn66zIVIXiE1Rel2s4A"
        target="_blank"
       sx={{mr:'0px'}}>
<div className='side-by-side'>

               <img src={youtube}/>
               
         </div>
       </Button>

       <Button
        component="a" href="https://www.twitch.tv/williamwhite18"
        target="_blank"
       sx={{mr:'0px'}}>
<div className='side-by-side'>

               <img src={twitch}/>
               
         </div>
       </Button>


</Box>


        <Box sx={{flexGrow: 1,  gap:"30px", justifyContent: "center", alignItems:"center", display: { xs: 'none', md: 'flex' } }}>

          <Button
          component={Link}
          to="/"
          sx={{
            maxWidth:"130px",
            textTransform:'none',
            margin:"5px"
          }}>
              <Box
            component="img"
            sx={{
            width: "100%",
            m:0
            }}
            src={logo} 
        />
          </Button>

          </Box>


          <Box  sx={{ width:"33.33%", flexGrow: 1, justifyContent: "flex-end", display: { xs: 'none', md:'flex'} }}>
           <NavDrawer/>
          </Box>
        


        </Toolbar>
      </Container>
      </AppBar>
      </div>
    </ThemeProvider>
  )};
};


export default ResponsiveAppBar;
