
import {
  CLEAR_CREATE_MILESTONE_SUCCESS, CREATE_MILESTONE,
  CREATE_MILESTONE_ERROR, CREATE_TAG, DELETE_COMMENT, DELETE_MILESTONE,
  DELETE_TAG, LIKE_MILESTONE, LOADING_COMMENTS, LOADING_DATA, SET_FILTER, SET_MORE_COMMENTS, SET_MORE_MILESTONES, SET_MILESTONE, SET_MILESTONES, SET_MILESTONE_INITIAL, SET_PRIVACY, SET_TERMS, SET_USERS,
  SET_USER_DISPLAY, STOP_LOADING_COMMENTS, STOP_LOADING_DATA, SUBMIT_COMMENT, UNLIKE_MILESTONE,
  VOTE_POLL,
  MILESTONE_SUCCESS,
  SET_VIDEOS
} from '../types';

const initialState = {
  milestones: [],
  videos:[],
  lastKey:null,
  filterOptions: {
      "FILTER_tag":"",
  },
  milestone: {},
  documents:{terms:null,privacy:null},
  loading: false,
  success:null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: true
      };
      case STOP_LOADING_DATA:
      return {
        ...state,
        loading: false
      };
    case SET_MILESTONES:
      return {
        ...state,
        milestones: action.payload.milestones,
        lastKey: action.payload.lastKey,
        loading: false
      };
      case SET_MORE_MILESTONES:
      return {
        ...state,
        milestones: state.milestones.concat(action.payload.milestones),
        lastKey: action.payload.lastKey,
        loading: false
      };
      case SET_MILESTONE_INITIAL:
        let milestone_index = state.milestones.findIndex(
          (milestone) => milestone.milestoneId === action.payload.milestoneId
        );
        if (milestone_index !== -1){
    let thisPost = state.milestones[milestone_index]
        return {
          ...state,
          milestone: thisPost,
        };
      } else {
        return{
          ...state,
        }
      }
    case SET_MILESTONE:
      return {
        ...state,
        milestone: action.payload.milestone,
        loading: false
      };
    case SET_FILTER:
      return {
        ...state,
        filterOptions: action.payload.filterOptions,
        
      };
      case SET_VIDEOS:
      return {
        ...state,
        videos: action.payload.videos,
        loading: false
      };

      case DELETE_MILESTONE:
        let delete_index = state.milestones.findIndex(
          (milestone) => milestone.milestoneId === action.payload
        );
        state.milestones.splice(delete_index, 1);
        return {
          ...state
        };

       

    case CREATE_MILESTONE:
      
        return {
          ...state,
          milestones:[
            action.payload,
            ...state.milestones
          ],
          success:true
        };

        case CREATE_MILESTONE_ERROR:
          return {
            ...state,
            success:false
          };

          case MILESTONE_SUCCESS:
          return {
            ...state,
            success:true,
            loading:false
          };

        case CLEAR_CREATE_MILESTONE_SUCCESS:
          return {
            ...state,
            success:null
          };


      case SET_TERMS:
        let new_documents = state.documents
        new_documents.terms = action.payload
        return {
          ...state,
          documents:new_documents
          // loading: false
        };
        case SET_PRIVACY:
          let new_documents2 = state.documents
          new_documents2.privacy = action.payload
          return {
            ...state,
            documents:new_documents2
            // loading: false
          };
    

        
    default:
      return state;
  }
}