import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { CustomImage } from "./images.ts";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';



function MilestoneGallery(props) {
  const [index, setIndex] = useState(-1);

  const handleClick = (index: number, item: CustomImage) => setIndex(index);

  console.log(props.photos)
  const photos = props.photos;
  let images = {}
  
   images = photos.map((x) => ({
    src: x,
    original: x,
    alt: "Will White Photo",
    width: 500,
    height: 500,
    caption:"Official William White . Com"
  })).filter((x) => x.src.length > 0);


  const slides = images.map(({ original, width, height }) => ({
    src: original,
    width,
    height,
  }));

  return (
    <div style={{width:"100%",justifyContent:"center"}}>
      <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
      rowHeight={300}
      />
      <Lightbox
        slides={slides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
      />
    </div>
  );
}



MilestoneGallery.propTypes = {
  photos: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  data: state.data,
  user: state.user,
  UI: state.UI,
})

export default connect(mapStateToProps, { })(MilestoneGallery);

