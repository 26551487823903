export default  {
    palette: {
      primary: {
        main: "#F36179",
        dark: "#EF3251"
      },
      secondary: {
        main: "#61F3DB"
      },
      info: {
        main: "#000"
      },
    },
    typography: {
        button: {
          textTransform: 'none',
          fontWeight:'bold'
        }
      },
      multilineColor:{
        color:'white'
    }
  }