import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import {app} from './utils/firebase'
// import Home from './pages/home'; 

// Redux 
import { connect } from "react-redux";
import { fetchUser } from './redux/actions/userActions'


import theme from './utils/theme';
import { styled, createTheme, ThemeProvider } from  "@mui/material/styles";
import AnimatedRoutes from './AnimatedRoutes';

const themeProvider = createTheme(theme);

class App extends Component {
  componentWillMount() {
  this.props.fetchUser();
  
}
render() {
  return (
    <ThemeProvider theme={themeProvider}>
       <Router>
    <AnimatedRoutes/>
      </Router>
      </ThemeProvider>
  );
}
};

export default connect(null, { fetchUser })(App);

