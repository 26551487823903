import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const AdminPrivateRoute = ({ authenticated, admin }) => {
 
  // let {auth,admin} = this.props;
  // console.log(this.state)
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  console.log(authenticated,admin)
  return authenticated && admin ? <Outlet /> : <Navigate to="/adminlogin" replace/>;
}

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
  admin: state.user.admin,
  
});

AdminPrivateRoute.propTypes = {
  user: PropTypes.object
};

export default connect(mapStateToProps)(AdminPrivateRoute);