// User reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_ADMIN = 'SET_ADMIN';
export const SET_UNADMIN = 'SET_UNADMIN';
export const SET_USER = 'SET_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOADING_USER = 'LOADING_USER';
// UI reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_DATA = 'LOADING_DATA';
export const STOP_LOADING_DATA = 'STOP_LOADING_DATA';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
export const LOADING_COMMENTS = 'LOADING_COMMENTS';
export const STOP_LOADING_COMMENTS = 'STOP_LOADING_COMMENTS';
export const SET_MESSAGE = 'SET_MESSAGE';
// Data reducer types
export const SET_MILESTONES = 'SET_MILESTONES';
export const SET_MORE_MILESTONES = 'SET_MORE_MILESTONES';
export const SET_MILESTONE = 'SET_MILESTONE';
export const SET_VIDEOS = 'SET_VIDEOS';
export const SET_MILESTONE_INITIAL = 'SET_MILESTONE_INITIAL';
export const SET_USERS = 'SET_USERS';
export const SET_USER_DISPLAY = 'SET_USER_DISPLAY';
export const LIKE_MILESTONE = 'LIKE_MILESTONE';
export const UNLIKE_MILESTONE = 'UNLIKE_MILESTONE';
export const VOTE_POLL = 'VOTE_POLL';
export const DELETE_MILESTONE = 'DELETE_MILESTONE';
export const DELETE_TAG = 'DELETE_TAG';
export const CREATE_MILESTONE = 'CREATE_MILESTONE';
export const CREATE_MILESTONE_ERROR = 'CREATE_MILESTONE_ERROR';
export const CLEAR_CREATE_MILESTONE_SUCCESS = 'CLEAR_CREATE_MILESTONE_SUCCESS';
export const MILESTONE_SUCCESS = 'MILESTONE_SUCCESS';
export const CREATE_TAG = 'CREATE_TAG';
export const SUBMIT_COMMENT = 'SUBMIT_COMMENT';
export const SET_MORE_COMMENTS = 'SET_MORE_COMMENTS';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const SET_FILTER = 'SET_FILTER';
export const SET_TERMS = 'SET_TERMS';
export const SET_PRIVACY = 'SET_PRIVACY';
