import React, { Component, Fragment } from 'react';

import { Link } from "react-router-dom";

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { List, ListItem, ListItemButton, ListItemText, ListItemIcon, Divider, Icon, IconButton } from '@mui/material'


import logo from '../media/WillNewLogo.png'

import instagram from '../media/icons/instagram_bw.png'
import tiktok from '../media/icons/tiktok_bw.png'
import youtube from '../media/icons/youtube_bw.png'
import twitch from '../media/icons/twitch_bw.png'

export class NavDrawer extends Component {
 
  state = {
    open:false,
    title:""
  }

  componentDidMount(){
    
    if (window.location.pathname == "/"){
      this.setState({title:"Home"})
    } 
    else if (window.location.pathname == "/about"){
      this.setState({title:"About"})
    } 
    else if (window.location.pathname == "/milestones"){
      this.setState({title:"Milestones"})
    } 
    else if (window.location.pathname.split("/")[1] == "milestones"){
      this.setState({title:"Milestone"})
    } 
    else if (window.location.pathname == "/videos"){
      this.setState({title:"Videos"})
    }
    else if (window.location.pathname == "/contact"){
      this.setState({title:"Contact"})
    }
    else if (window.location.pathname == "/privacy"){
      this.setState({title:"Privacy"})
    } 
    else if (window.location.pathname == "/terms"){
      this.setState({title:"Terms & Conditions"})
    } 
  }

  closeDrawer = () => (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ open: false });
  };

  openDrawer = () => (event) => {
    this.setState({ open: true });
  };

  render(){
    let {isOpen} = this.state;

  return (
    
    <div>
          <Button onClick={this.openDrawer()}>
            <p style={{marginRight:"10px", color:"black",fontWeight:"600"}}>{this.state.title}</p>
            <MenuIcon color="info" fontSize="large"/>
            </Button>
          <SwipeableDrawer
            anchor="right"
            open={this.state.open}
            onClose={this.closeDrawer()}
            onOpen={this.openDrawer()}
            transitionDuration = {300}
          >
            <Box
        sx={{ width: { xs: "85vw", md:"50vw"} , color:"#141414", display:'flex', alignItems:'center' }}
        role="presentation"
       
      >

      <div className='drawer-container'>

      <Button
          component={Link}
          to="/"
          sx={{
            maxWidth:"130px",
            textTransform:'none',
            margin:"5px"
          }}>
              <Box
            component="img"
            sx={{
            width: "100%",
            m:0
            }}
            src={logo} 
        />
          </Button>

          <Button
        component={Link}
        to='/about'
        sx={{mr:'0px'}}>
          <Typography className='nav-text'
          sx={{fontWeight: "500", fontSize: 18, textTransform:'uppercase'}}>Meet William</Typography>
        </Button>

      <Button
        component={Link}
        to='/milestones'
        sx={{mr:'0px'}}>
          <Typography className='nav-text'
          sx={{fontWeight: "500", fontSize: 18, textTransform:'uppercase'}}>Milestones</Typography>
        </Button>

        <Button
        component={Link}
        to='/videos'
        sx={{mr:'0px'}}>
          <Typography className='nav-text'
          sx={{fontWeight: "500", fontSize: 18, textTransform:'uppercase'}}>Videos</Typography>
        </Button>

        {/* <Button
        component="a" href="https://shopwilliamwhite.com/"
        target="_blank"
        sx={{mr:'0px'}}>
          <Typography className='nav-text'
          sx={{fontWeight: "500", fontSize: 18, textTransform:'uppercase'}}>Shop</Typography>
        </Button> */}
        
        <Button
        component={Link}
        to='/contact'
        sx={{mr:'0px'}}>
          <Typography className='nav-text'
          sx={{fontWeight: "500", fontSize: 18, textTransform:'uppercase'}}>Contact</Typography>
        </Button>

          
        

<div className='side-by-side'>

<Button
        component="a" href="https://www.instagram.com/williamwhite18/"
        target="_blank"
       sx={{mr:'0px'}}>
<div className='side-by-side'>

               <img src={instagram}/>
               
         </div>
       </Button>

       <Button
       component="a" href="https://www.tiktok.com/@whiteyy18?"
       target="_blank"
       sx={{mr:'0px'}}>
           <div className='side-by-side'>
           
               <img src={tiktok}/>
               
         </div>
       </Button>

       <Button
        component="a" href="https://www.youtube.com/channel/UCVJvvn66zIVIXiE1Rel2s4A"
        target="_blank"
       sx={{mr:'0px'}}>
<div className='side-by-side'>

               <img src={youtube}/>
               
         </div>
       </Button>

       <Button
        component="a" href="https://www.twitch.tv/williamwhite18"
        target="_blank"
       sx={{mr:'0px'}}>
<div className='side-by-side'>

               <img src={twitch}/>
               
         </div>
       </Button>
       </div>      

     

      </div>

            </Box>
          </SwipeableDrawer>
    </div>
  );
  }
}   



export default NavDrawer