import { useParams } from "react-router-dom";
import Milestone from "../pages/milestone";

export function withRouter(Children){
  return(props)=>{

     const match  = {params: useParams()};
     return <Children {...props}  match = {match}/>
 }
}
