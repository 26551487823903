import axios from 'axios';
import {  } from '../../utils/Global';
import { CLEAR_CREATE_MILESTONE_SUCCESS, CLEAR_ERRORS, SET_VIDEOS, CREATE_MILESTONE, CREATE_TAG, DELETE_COMMENT, DELETE_MILESTONE, DELETE_TAG, LIKE_MILESTONE, LOADING_DATA, LOADING_UI, SET_ERRORS, SET_FILTER, SET_MORE_COMMENTS, SET_MORE_MILESTONES, SET_MILESTONE, SET_MILESTONES, SET_MILESTONE_INITIAL, SET_PRIVACY, SET_TERMS, SET_USERS, SET_USER_DISPLAY, STOP_LOADING_DATA, STOP_LOADING_UI, SUBMIT_COMMENT, UNLIKE_MILESTONE, VOTE_POLL, MILESTONE_SUCCESS } from '../types';



axios.defaults.baseURL = '/api';

export const getAllMilestones = () => dispatch => {
  dispatch({ type: LOADING_DATA });
  axios.get('/getAllMilestones')
  .then(res => {
    dispatch({
      type: SET_MILESTONES,
      payload: res.data
    });
  })
    .catch(err => {
      const status = err.response.status
      if (status === 403){
        dispatch({
          type: SET_ERRORS,
          payload: {unsubscribed:"UnsubscribedError"}
        })
      } 
      else if (status === 500){
        dispatch({
          type: SET_ERRORS,
          payload: {data:"DataLoadingError"}
        })
      }
      
      dispatch({
        type: SET_MILESTONES,
        payload: {milestones: [],
          lastKey: null}
      })
    })
};

export const getAllPublishedMilestones = () => dispatch => {
  dispatch({ type: LOADING_DATA });
  axios.get('/getAllPublishedMilestones')
  .then(res => {
    dispatch({
      type: SET_MILESTONES,
      payload: res.data
    });
  })
    .catch(err => {
      const status = err.response.status
      if (status === 403){
        dispatch({
          type: SET_ERRORS,
          payload: {unsubscribed:"UnsubscribedError"}
        })
      } 
      else if (status === 500){
        dispatch({
          type: SET_ERRORS,
          payload: {data:"DataLoadingError"}
        })
      }
      
      dispatch({
        type: SET_MILESTONES,
        payload: {milestones: [],
          lastKey: null}
      })
    })
};

export const loadMorePosts = (lastKey) => dispatch => {
  // dispatch({ type: LOADING_DATA });
  
  axios.get(`/loadMorePosts/${lastKey}`)
  .then(res => {
    dispatch({
      type: SET_MORE_MILESTONES,
      payload: res.data
    });
  })
    .catch(err => {
      dispatch({
        type: SET_MORE_MILESTONES,
        payload: []
      })
    })
};

export const getFilteredPosts = (filterData) => dispatch => {
  dispatch({ type: LOADING_DATA });
  let filterDataPost = {
    params:{
    "FILTER_tag":filterData.FILTER_tag,
    "FILTER_dateStart":filterData.FILTER_dateStart,
    "FILTER_dateEnd":filterData.FILTER_dateEnd,
    "FILTER_type":filterData.FILTER_type
    }
  }
  
  axios.get('/homeFiltered',filterDataPost)
  .then(res => {
    dispatch({
      type: SET_MILESTONES,
      payload: res.data
    });
  })
    .catch(err => {
      const status = err.response.status
      if (status === 403){
        dispatch({
          type: SET_ERRORS,
          payload: {unsubscribed:"UnsubscribedError"}
        })
      } 
      else if (status === 500){
        dispatch({
          type: SET_ERRORS,
          payload: {data:"DataLoadingError"}
        })
      }
      
      dispatch({
        type: SET_MILESTONES,
        payload: {posts: [],
          lastKey: null,
          tags:[]}
      })
    })
};

export const loadMoreFilteredPosts = (lastKey,filterData) => dispatch => {
  // dispatch({ type: LOADING_DATA });
  let filterDataPost = {
    params:{
    "FILTER_tag":filterData.FILTER_tag,
    "FILTER_dateStart":filterData.FILTER_dateStart,
    "FILTER_dateEnd":filterData.FILTER_dateEnd,
    "FILTER_type":filterData.FILTER_type
    }
  }
  
  axios.get(`/loadMoreFilteredPosts/${lastKey}`)
  .then(res => {
    dispatch({
      type: SET_MORE_MILESTONES,
      payload: res.data
    });
  })
    .catch(err => {
      dispatch({
        type: SET_MORE_MILESTONES,
        payload: []
      })
    })
};

export const setFilterOptions = (filterOptions) => dispatch => {
    dispatch({
      type: SET_FILTER,
      payload: {filterOptions}
    })
     
};

export const getMilestone = (slug) => dispatch => {
  dispatch({ type: LOADING_DATA });
  axios.get(`/milestones/${slug}`)
  .then(res => {
    dispatch({
      type: SET_MILESTONE,
      payload: res.data
    })
    dispatch({type: STOP_LOADING_DATA})
  })
    .catch(err => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    })
};

export const getAllVideos = () => dispatch => {
  dispatch({ type: LOADING_DATA });
  axios.get('/getAllVideos')
  .then(res => {
    dispatch({
      type: SET_VIDEOS,
      payload: res.data
    });
  })
    .catch(err => {
      const status = err.response.status
      if (status === 403){
        dispatch({
          type: SET_ERRORS,
          payload: {unsubscribed:"UnsubscribedError"}
        })
      } 
      else if (status === 500){
        dispatch({
          type: SET_ERRORS,
          payload: {data:"DataLoadingError"}
        })
      }
      
      dispatch({
        type: SET_VIDEOS,
        payload: []
      })
    })
};




export const setPostInitial = (postId) => dispatch => {
    dispatch({
      type: SET_MILESTONE_INITIAL,
      payload: {postId}
    })
};

export const clearPost = () => dispatch => {
  dispatch({
    type: SET_MILESTONE,
    payload: {}
  });
};


export const deletePost = (postId) => (dispatch) => {
  axios
    .delete(`/post/${postId}`)
    .then(() => {
      dispatch({ type: DELETE_MILESTONE, payload: postId });
    })
    .catch((err) => 
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    })
    )
};

export const createPost = (newPost) => (dispatch) => {
  dispatch({ type: LOADING_UI });
};

 

export const clearCreatePostSuccess = () => (dispatch) => {
 
      dispatch({
        type: CLEAR_CREATE_MILESTONE_SUCCESS
      });
    
};

export const createMilestone = (newMilestone) => (dispatch) => {
  dispatch({ type: LOADING_DATA });
   axios
    .post('/createMilestone', newMilestone)
    .then(()=>{
      return dispatch({
        type: MILESTONE_SUCCESS
      })
    })
    .catch((err) => 
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    }));

};

export const editMilestoneDetails = (milestoneDetails) => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  axios
    .post(`/milestone/${milestoneDetails.milestoneId}`, milestoneDetails)
    .then(()=>{
      return dispatch({
        type: MILESTONE_SUCCESS
      })
    })
    .catch((err) => 
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    }));
};

export const deleteMilestone = (milestoneId) => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  axios
    .delete(`/milestone/${milestoneId}`)
    .then(()=>{
      return dispatch({
        type: MILESTONE_SUCCESS
      })
    })
    .catch((err) => 
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    }));
};

export const clearSuccess = () => (dispatch) => {
  return dispatch({
    type: CLEAR_CREATE_MILESTONE_SUCCESS
  })
};


// export const getTerms = (terms) => (dispatch) => {
//   let termsReturn 
//   dispatch({ type: LOADING_DATA });
//   axios
//     .get(`/documents/terms`)
//     .then((res) => {
//       termsReturn = res.data;
//       const DBEditorState = JSON.parse(termsReturn.content);
//       const contentState = convertFromRaw(DBEditorState);
//       const editorState = EditorState.createWithContent(contentState);
//       dispatch({
//         type: SET_TERMS,
//         payload: editorState
//       });
//     })
//     .catch((err) => {
//       dispatch({
//         type: SET_ERRORS,
//         payload: err.response.data
//       })
//     });
    
// };


// export const uploadTerms = (terms) => (dispatch) => {
//   dispatch({ type: LOADING_DATA });
//   axios
//     .post(`/documents/terms`, terms)
//     .then(() => {
//       dispatch(getTerms());
//     })
//     .catch((err) => 
//     dispatch({
//       type: SET_ERRORS,
//       payload: err.response.data
//     }));
// };


// export const getPrivacy = (privacy) => (dispatch) => {
//   let privacyReturn 
//   dispatch({ type: LOADING_DATA });
//   axios
//     .get(`/documents/privacy`)
//     .then((res) => {
//       privacyReturn = res.data;
//       const DBEditorState = JSON.parse(privacyReturn.content);
//       const contentState = convertFromRaw(DBEditorState);
//       const editorState = EditorState.createWithContent(contentState);
//       dispatch({
//         type: SET_PRIVACY,
//         payload: editorState
//       });
//     })
//     .catch((err) => {
//       dispatch({
//         type: SET_ERRORS,
//         payload: err.response.data
//       })
//     });
    
// };


// export const uploadPrivacy = (privacy) => (dispatch) => {
//   dispatch({ type: LOADING_DATA });
//   axios
//     .post(`/documents/privacy`, privacy)
//     .then(() => {
//       dispatch(getPrivacy());
//     })
//     .catch((err) =>  
//     dispatch({
//       type: SET_ERRORS,
//       payload: err.response.data
//     }));
// };


export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};