import React, { Component } from 'react'
// import ResponsiveAppBarComingSoon from '../components/HomeAppBar'
import DocumentMeta from 'react-document-meta';
import theme from '../utils/theme';
import '../css/contact.css'

import { styled, createTheme, ThemeProvider } from  "@mui/material/styles";

import { Divider, Button, Typography, Fade, TextField } from '@mui/material';
import ResponsiveAppBar from '../components/Appbar';
import Footer from '../components/Footer';


const themeProvider = createTheme(theme);

const meta = {
  title: 'Contact | Official William White',
  description: '',
  canonical: 'http://officialwilliamwhite.com/contact',
  meta: {
      charset: 'utf-8',
      name: {
          keywords: 'react,meta,document,html,tags,contact,william,will,white,tiktok,model'
      }
  }
};

export class contact extends Component {

    state={
        subject:"" ,
        message:""
      }


    componentDidMount(){
        document.title = "Contact | Official William White"
        window.scrollTo(0, 0);
      }

      handleSubmit = (event) => {
        console.log(this.state.message);
        window.open(`mailto:inquiries@officialwilliamwhite.com?subject=${this.state.subject}&body=${this.state.message}`);
      };

  
    
      handleChange = (event) => {
        this.setState({
          [event.target.name]: event.target.value,
        });
      };

  render() {
    return (
      <div>
        <ResponsiveAppBar/>
        <DocumentMeta {...meta} />
        <ThemeProvider theme={themeProvider}>
          
            <div className="contact-container">
            <Fade in={true} timeout={1000}>
            <div className='contact-banner'>
           <h1>Contact</h1>
        <p>For all business related inquiries please fill out the form below.</p>
           <div className='email-form'>
            <TextField 
            id="subject"
            name="subject"
            type="text"
            label="Subject"
            variant='outlined'
            color="primary"
            placeholder='Email'
            onChange={this.handleChange}
            fullWidth/>
            <TextField 
            id="message"
            name="message"
            type="text"
            label="Message"
            variant='outlined'
            color="primary"
            placeholder='Message'
            onChange={this.handleChange}
            fullWidth
            multiline
            minRows={10}/>
            <Button
            onClick={this.handleSubmit}
           variant='contained'
           disableElevation
           >
            Compose Email
           </Button>
           </div>

           

            </div>
</Fade>
<Footer/>
        </div>
            </ThemeProvider>
      </div>
    )
  }
}

export default contact