import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../css/admin.css'

import { createMilestone, clearSuccess } from '../redux/actions/dataActions';
import { logoutUser } from '../redux/actions/userActions';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


import { CircularProgress, Button, Card, Fade, TextField, Modal, Box, Radio, RadioGroup, FormControlLabel, FormLabel, FormControl, Switch, InputLabel, Select, OutlinedInput, Chip, MenuItem } from '@mui/material';

import Moment from 'moment';

const available_tags = [
  'campaign',
  'digitals',
  'award',
  'press',
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

export class NewMilestone extends Component {

  initial_state={
    milestoneId:"",
    isPublished:true,
    date:new Date(),
    type:"",
    title:"",
    slug:"",
    mainExternalLink: "",
    photos:[""],
    videoEmbed:"",
    thumbnail:"",
    titleImage:"",
    description:"",
    body:"",
    tags:[],
    saved:false
  }
    state={
        milestoneId:"",
        isPublished:true,
        date:new Date(),
        type:"",
        title:"",
        slug:"",
        mainExternalLink: "",
        photos:[""],
        videoEmbed:"",
        thumbnail:"",
        titleImage:"",
        description:"",
        body:"",
        tags:[],
        saved:false
    }

    componentDidMount(){
    
      }

    handleChange = (event) => {
        this.setState({
          [event.target.name]: event.target.value,
        });
      };

      handleTitleChange = (event) => {
        this.setState({
          title: event.target.value,
          slug: event.target.value.replace(/\s+/g, '-').toLowerCase()
        });
      };

      inputPhoto = (event,index) => {
        let new_photos = this.state.photos
        new_photos[index] = event.target.value
        this.setState({
          photos: new_photos,
        });
      };

      addPhoto = () => {
        let new_photos = this.state.photos
        new_photos.push("")
        this.setState({
          photos: new_photos,
        });
      };

      handleSwitch = (event) => {
        
        this.setState({
          [event.target.name]: !this.state.isPublished,
        });
      };

      selectTag = (tag) => {
        let tags = this.state.tags
        if (tags.includes(tag)){
          const index = tags.indexOf(tag);
          if (index > -1) { // only splice array when item is found
            tags.splice(index, 1); // 2nd parameter means remove one item only
          }
        } else {
          tags.push(tag)
        }
        this.setState({
          tags
        });
      };

      handleDateChange = (date) => {
        
        this.setState({
          date: date
        });
      };


      handleSubmit = () => {
        const milestoneDetails = {
          milestoneId:this.state.milestoneId,
          isPublished:this.state.isPublished,
          type:this.state.type,
          title:this.state.title,
          slug:this.state.slug,
          date:this.state.date,
          mainExternalLink: this.state.mainExternalLink,
          photos:this.state.photos,
          videoEmbed:this.state.videoEmbed,
          thumbnail:this.state.thumbnail,
          titleImage:this.state.titleImage,
          description:this.state.description,
          body:this.state.body,
          tags:this.state.tags
        };
        console.log(milestoneDetails);
        this.props.createMilestone(milestoneDetails);
        this.setState(this.initial_state)
      };

      handleClose = () => {
        this.props.clearSuccess()
        this.props.onClose()
      }


  render() {
    let {open,onClose,milestone} = this.props;
    let {isPublished, title,type, mainExternalLink, photos, videoEmbed, slug,thumbnail,description,titleImage,body,tags, date} = this.state;
    const { success, loading } = this.props.data;

    return (
      <div>
        <Modal
  open={open}
//   onClose={onClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box>
    <div className='milestone-details-containter'>

        {loading ? (
            <CircularProgress/>
        ) : (
            <div>
            { success ? (
                <div>
                <h1>Success</h1>
                <Button
                onClick={()=>this.handleClose()}
                variant="outlined"
                size='large'
                fullWidth
                >
                  Close
                </Button>
                </div>
            ) :
            (
                <div className='milestone-details-form'>
    
    
  <FormControlLabel control={<Switch
    name="isPublished"
    title='Published'
    value={isPublished}
    checked={isPublished}
    onChange={this.handleSwitch}
    inputProps={{ 'aria-label': 'controlled' }}
    />} label="Published">

    </FormControlLabel>
  


    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Milestone Type</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="type"
        value={type}
        onChange={this.handleChange}
      >
        <FormControlLabel value="link" control={<Radio />} label="External Link" />
        <FormControlLabel value="photo" control={<Radio />} label="Photos" />
        <FormControlLabel value="video" control={<Radio />} label="Video" />
      </RadioGroup>
    </FormControl>


    <TextField
        id="title"
        name="title"
        type="text"
        label="Title"
        rows="1"
        variant="outlined"
        placeholder="Milestone title..."
        // error={errors.title ? true : false}
        // helperText={errors.title}
        className="textfield"
        value={title}
        onChange={this.handleTitleChange}
        fullWidth
    />

    <TextField
        id="slug"
        name="slug"
        type="text"
        label="Slug"
        rows="1"
        variant="outlined"
        placeholder="The Slug is the end of the URL Link"
        // error={errors.slug ? true : false}
        // helperText={errors.slug}
        className="textfield"
        value={slug}
        onChange={this.handleChange}
        fullWidth
    />

<div className='side-by-side'>
<p>Date</p>
<DatePicker selected={date} onChange={(date) => this.handleDateChange(date)} />
</div>


<TextField
        id="thumbnail"
        name="thumbnail"
        type="text"
        label="Thumbnail URL"
        rows="1"
        variant="outlined"
        placeholder="Thumbnail URL"
        // error={errors.thumbnail ? true : false}
        // helperText={errors.thumbnail}
        className="textfield"
        value={thumbnail}
        onChange={this.handleChange}
        fullWidth
    />


<TextField
        id="titleImage"
        name="titleImage"
        type="text"
        label="Title Photo URL"
        rows="1"
        variant="outlined"
        placeholder="Title Photo URL"
        // error={errors.titleImage ? true : false}
        // helperText={errors.titleImage}
        className="textfield"
        value={titleImage}
        onChange={this.handleChange}
        fullWidth
    />


<TextField
        id="description"
        name="description"
        type="text"
        label="Description (Optional)"
        rows="3"
        multiline
        variant="outlined"
        placeholder="Short Form Description of Milestone"
        // error={errors.description ? true : false}
        // helperText={errors.description}
        className="textfield"
        value={description}
        onChange={this.handleChange}
        fullWidth
    />

    <TextField
        id="body"
        name="body"
        type="text"
        label="Main Body"
        rows="3"
        multiline
        variant="outlined"
        placeholder="Main Body Of Milestone"
        // error={errors.body ? true : false}
        // helperText={errors.body}
        className="textfield"
        value={body}
        onChange={this.handleChange}
        fullWidth
    />



<div className='tags'>
  <p>Tags</p>
                      {available_tags.map((t, i) => 
                      <Button
                      onClick={() => this.selectTag(t)}
                      color='info'>
                      <p className={tags.includes(t) ? "tag-selected" : "tag-select"}>{t.toUpperCase()}</p>
                      </Button>
                      )}
                      </div>


    {/* {type == "link" ? ( */}
         <TextField
         id="mainExternalLink"
         name="mainExternalLink"
         type="text"
         label="External Link"
         rows="1"
         variant="outlined"
         placeholder="Main External Link..."
         // error={errors.title ? true : false}
         // helperText={errors.title}
         className="textfield"
         value={mainExternalLink}
         onChange={this.handleChange}
         fullWidth
     />
    {/* ):(null)} */}

    {/* {type == "photo" ? ( */}
        <div>
       { photos.map((p, i) => 
        <TextField
        id={`photo${i}`}
        name={`photo${i}`}
        type="text"
        label={`Photo ${i+1} URL`}
        rows="1"
        variant="outlined"
        placeholder={`Photo ${i+1} URL`}
        // error={errors.title ? true : false}
        // helperText={errors.title}
        className="textfield"
        value={photos[i]}
        onChange={(event) => this.inputPhoto(event,i)}
        fullWidth
    />
            )}
    <Button
      onClick={this.addPhoto}
      >
        Add Photo
      </Button>
      </div>
        
    {/* ):(null)} */}

{/* {type == "video" ? ( */}
         <TextField
         id="videoEmbed"
         name="videoEmbed"
         type="text"
         label="YouTube Video ID"
         rows="1"
         variant="outlined"
         placeholder="Example: IX8gR5kqYtA"
         // error={errors.title ? true : false}
         // helperText={errors.title}
         className="textfield"
         value={videoEmbed}
         onChange={this.handleChange}
         fullWidth
     />
    {/* ):(null)} */}

      {/* <div className='side-by-side'> */}
      <Button
      onClick={this.handleSubmit}
      variant="contained"
      size='large'
      fullWidth
      >
        Save
      </Button>

      <Button
      onClick={()=>this.handleClose()}
      variant="outlined"
      size='large'
      fullWidth
      >
        Cancel
      </Button>
      {/* </div> */}
      </div>
            )}
</div>
        )}
    
      </div>
  </Box>
</Modal>

      </div>
    )
  }
}



const mapStateToProps = (state) => ({
    data: state.data,
    UI: state.UI,
  });
  
  const mapActionsToProps = { createMilestone,clearSuccess };
  
  NewMilestone.propTypes = {
    createMilestone: PropTypes.func.isRequired,
    clearSuccess: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
  };
  
  export default connect(
    mapStateToProps,
    mapActionsToProps
  )(NewMilestone);