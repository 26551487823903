import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DocumentMeta from 'react-document-meta';
import { Parallax, ParallaxProvider,ParallaxBanner, useParallax } from 'react-scroll-parallax'

import axios from 'axios'
import ResponsiveAppBar from '../components/Appbar';
import { VideosBanner } from '../components/VideosBanner';

import theme from '../utils/theme';
import '../css/videos.css'

import { getAllVideos } from '../redux/actions/dataActions';

import { createTheme, ThemeProvider } from  "@mui/material/styles";

import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import { Box, Button, Typography, Fade, Modal, Pagination, Card, CircularProgress, Slide } from '@mui/material';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Footer from '../components/Footer';

const themeProvider = createTheme(theme);

const meta = {
  title: 'Videos | Official William White',
  description: '',
  canonical: 'http://officialwilliamwhite.com/videos',
  meta: {
      charset: 'utf-8',
      name: {
          keywords: 'react,meta,document,html,tags,videos,william,will,white,tiktok,model'
      }
  }
};

export class videos extends Component {

    state={
        openVideo:false,
        openVideoId:null
      }


    componentDidMount(){
        document.title = "Videos | Official William White"
        window.scrollTo(0, 0);
        this.props.getAllVideos();
          
      }

      openVideo = (videoId) => {
        this.setState({openVideo:true,openVideoId:videoId})
      };

      handleClose = () => {
        this.setState({openVideo:false,openVideoId:null})
      };
      
    
  render() {
    dayjs.extend(relativeTime);

    const { videos, loading } = this.props.data;

    
    return (
        <div>
        
        <ResponsiveAppBar/>
        <DocumentMeta {...meta} />
        <ThemeProvider theme={themeProvider}>
            <div className="videos-container">
           
           <div className='videos-banner'>
            <h1>Videos</h1>
            <Button 
            variant='text'
            size='small'
            color='info'
    component="a" href="https://www.youtube.com/channel/UCVJvvn66zIVIXiE1Rel2s4A?sub_confirmation=1"
    target="_blank"
    disableElevation
    sx={{padding:"5px 10px",display:"flex",gap:"10px"}}
>
<SubscriptionsIcon/>
  Subscribe 
</Button>
           </div>


                {loading && videos.length == 0 ? (
  <CircularProgress/>
) : (
  
<Slide direction='up' in={true} timeout={500}>


<div className='videos-grid-container'>
{videos.map((e, i) => 
                  
                    <Button onClick={() => this.openVideo(e.videoId)} sx={{padding:"0px",width:"100% !important"}}>
                    <div className='youtube-item'>
                      <img src={e.thumbnail} />
                      <div className='details'>
                      <p className='title'>{e.title}</p>
                      <p className='date'>{dayjs(e.publishedAt).fromNow()}</p>
                    
                      
                      </div>
                      
                     
                    </div>
                    </Button>
                    
                  )}

                

                </div> 
                </Slide>
)}

                {/* <Pagination count={10} page={1} 
                variant="outlined" color="primary"
                onChange={() => console.log("Damn")} /> */}
                

        </div>

        
        <Modal
  open={this.state.openVideo}
  onClose={this.handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box className='video-modal'
  onClick={() => console.log(this.state)} >
    { this.state.openVideoId !== null ? (
      <div className='video-container'>
  <iframe
      src={`https://www.youtube.com/embed/${this.state.openVideoId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
      className="youtube-video"
    /></div>) : (null)
    }
  </Box>
</Modal>
        
        <Footer/>
            </ThemeProvider>
      </div>
    )
  }
}

  
videos.propTypes = {
  getAllVideos: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  data: state.data,
  user: state.user,
  UI: state.UI,
})

export default connect(mapStateToProps, { getAllVideos })(videos);

// <div className='videos-banner'>
// <Slide in={true} direction='right' timeout={500}>
//     <h1>Videos</h1>
//     </Slide>
    
    
// <div className='subscribe-button-container'>
// <Slide in={true} direction='left' timeout={600}>
//     <Button 
//     component="a" href="https://www.youtube.com/channel/UCVJvvn66zIVIXiE1Rel2s4A?sub_confirmation=1"
//     target="_blank"
//     disableElevation
//     sx={{padding:"0px",width:"100%"}}
// >
//   <h2 className="subscribe-button">Subscribe To Will's YouTube Channel</h2>
  
// </Button>
// </Slide>
// </div>
// </div>