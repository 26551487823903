import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DocumentMeta from 'react-document-meta';
import MediaQuery from 'react-responsive'


import ResponsiveAppBar from '../components/Appbar';

import theme from '../utils/theme';
import '../css/milestones.css'

import { getAllPublishedMilestones } from '../redux/actions/dataActions';


import { createTheme, ThemeProvider } from  "@mui/material/styles";

import { Divider, Button, Typography, Fade, TextField, Pagination, Card, CircularProgress, Grow, Slide } from '@mui/material';

import SortIcon from '@mui/icons-material/Sort';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Footer from '../components/Footer';



const themeProvider = createTheme(theme);

const meta = {
  title: 'Milestones | Official William White',
  description: '',
  canonical: 'http://officialwilliamwhite.com/milestones',
  meta: {
      charset: 'utf-8',
      name: {
          keywords: 'react,meta,document,html,tags,milestones,william,will,white,tiktok,model'
      }
  }
};

export class milestones extends Component {

    state={
        filter:0,
        showFilter:false,
        searchText:""
      }


    componentDidMount(){
        document.title = "Milestones | Official William White"
        this.props.getAllPublishedMilestones();
        window.scrollTo(0, 0);
      }

    handleSearchChange = (event) => {
        this.setState({
          [event.target.name]: event.target.value,
        });
      };


      setFilter(index){
        this.setState({filter:index})
      }

      toggleShowFilter(){
        this.setState({showFilter:!this.state.showFilter})
      }
      
    
  render() {
    dayjs.extend(relativeTime);

    const { milestones, lastKey, loading, post, filterOptions } = this.props.data;

    let milestonesFiltered = this.state.filter == 0 ? milestones : this.state.filter == 1 ? milestones.filter(milestone => milestone.tags.includes("award")) : this.state.filter == 2 ? milestones.filter(milestone => milestone.tags.includes("campaign")) : this.state.filter == 3 ? milestones.filter(milestone => milestone.tags.includes("press")) :  milestones
    
    let milestonesSearched = this.state.searchText.length > 0 ? milestonesFiltered.filter(milestone => milestone.title.toLowerCase().includes(this.state.searchText.toLowerCase()) || milestone.description.includes(this.state.searchText)) : milestonesFiltered


    return (
        <div>
        
        <ResponsiveAppBar/>
        <DocumentMeta {...meta} />
        <ThemeProvider theme={themeProvider}>
            <div className="milestones-container">
            
            <div className='milestones-banner'>
              <h1>Milestones</h1>

            <div className='search-bar'>
              <TextField
              id="searchText"
              name="searchText"
              type="text"
              label="Search"
              value={this.state.searchText}
              onChange={this.handleSearchChange}
              variant='standard'
              placeholder='Search'
              fullWidth/>

              <Button
              color='info'
              variant='text'
              sx={{display:"flex",gap:1}}
              onClick={() => this.toggleShowFilter()}
              >
               <SortIcon/> 
                Filter

                
                </Button>

                </div>

              

             
            </div>

            {this.state.showFilter ? (

<div className='milestones-filter-container'>
            <Slide direction='left' in={true} timeout={500}>
            <Button
            variant={this.state.filter == 0 ? 'contained' : 'outlined'}
            size='small'
            color='info'
            onClick={() => this.setFilter(0)}
           disableElevation
           sx={{padding:"0px"}}
           >See All
           </Button>
           </Slide>

           <Slide direction='left' in={true} timeout={600}>
           <Button
           variant={this.state.filter == 1 ? 'contained' : 'outlined'}
           size='small'
           color='info'
            onClick={() => this.setFilter(1)}
           disableElevation
           sx={{padding:"0px"}}
           >
            
            Awards
           </Button>
           </Slide>
           <Slide direction='left' in={true} timeout={700}>
           <Button
           variant={this.state.filter == 2 ? 'contained' : 'outlined'}
           size='small'
           color='info'
            onClick={() => this.setFilter(2)}
           disableElevation
           sx={{padding:"0px"}}
           >
            Campaigns
           </Button>
           </Slide>
           <Slide direction='left' in={true} timeout={900}>
           <Button
           variant={this.state.filter == 3 ? 'contained' : 'outlined'}
           size='small'
           color='info'
            onClick={() => this.setFilter(3)}
           disableElevation
           sx={{padding:"0px"}}
           >
            Press
           </Button>
           </Slide>
           
                </div>
                

          ) : (
            null
          )}
       




{ loading && milestones.length == 0 ? (
  <div className='loading-container'>
  <CircularProgress/>
  </div>
) : (
<Slide direction='up' in={true} timeout={500}>
<div className='milestones-grid-container'>


                  {milestonesSearched.map((e, i) => 
                  <Button
                  component={e.isLink ? "a" : Link}
                  target={e.isLink ? "_blank" : ""}
                  to={e.isLink ? "" : e.slug}
                  sx={{padding:"0px",width:"100% !important"}}
                  href={e.isLink ? e.mainExternalLink : e.slug}
                  onClick={()=>console.log(e.mainExternalLink)}
                  >
                  
                    <div className='milestone-item'>
                      <div className='thumbnail-container'>
                      <img src={e.thumbnail}/>
                      {/* <div className='tags'>
                      {e.tags.map((t, i) => 
                      <p className='tag'>{t.toUpperCase()}</p>
                      )}
                      
                      </div> */}
                      { e.isLink ? (
                      <div className='link'>
                      <p><i>{e.mainExternalLink}</i></p>
                      </div>
                      ) : (null)
                      }
                      </div>
                      <h3 className='title'>{e.title}</h3>
                      <p className='date'>{dayjs(e.createdAt).fromNow()}</p>
                      
                      {/* <p className ='desc'>{e.description}</p> */}
                      
                      
                     
                    </div>
                    
                    </Button>
                  )}
                

                </div>

                
                </Slide>
)}


                {/* <Pagination count={10} page={1} 
                variant="outlined" color="primary"
                onChange={() => console.log("Damn")} /> */}


                

        </div>
        <Footer/>
            </ThemeProvider>
      </div>
    )
  }
}

  
milestones.propTypes = {
  getAllPublishedMilestones: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  data: state.data,
  user: state.user,
  UI: state.UI,
})

export default connect(mapStateToProps, { getAllPublishedMilestones })(milestones);



