import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DocumentMeta from 'react-document-meta';
import { withRouter } from '../components/MilestoneWrapper';

import ResponsiveAppBar from '../components/Appbar';

import theme from '../utils/theme';
import '../css/milestone.css'

// import { Gallery } from "react-grid-gallery";


import { getMilestone,getAllPublishedMilestones } from '../redux/actions/dataActions';

import { createTheme, ThemeProvider } from  "@mui/material/styles";

import { Divider, Button, Typography, Fade, TextField, Pagination, Card, CircularProgress } from '@mui/material';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import Footer from '../components/Footer';

import MilestoneGallery from '../components/MilestoneGallery.tsx';

const themeProvider = createTheme(theme);

const meta = {
 
};

export class milestone extends Component {

  state = {
    loadingArticle:true,
    // meta:{
    //   title: 'Milestone | Official William White',
    //   description: '',
    //   canonical: 'http://officialwilliamwhite.com/about',
    //   meta: {
    //       charset: 'utf-8',
    //       name: {
    //           keywords: 'react,meta,document,html,tags,about,william,will,white,tiktok,model'
    //       }
    //   }
    // }
  }


  componentDidMount(){
    this.setState({loadingArticle:true})
    if (Object.keys(this.props.data.milestone).length === 0) {
      this.props.getMilestone(this.props.match.params.slug);
    }
    else if (this.props.data.milestone.slug !== this.props.match.params.slug){
    this.props.getMilestone(this.props.match.params.slug);
    }
    // if (this.props.data.milestones.length == 0){
    //   this.props.getAllPublishedMilestones();
    // }
    this.setState({loadingArticle:false})
    window.scrollTo(0, 0);
  }

  setMeta(title,description,slug){
    this.setState({meta:{
      title: `${title} | Official William White`,
      description: description,
      canonical: `http://officialwilliamwhite.com/milestones/${slug}`,
      meta: {
          charset: 'utf-8',
          name: {
              keywords: 'react,meta,document,html,tags,william,will,white,tiktok,model'
          }
      }
    }})
  }

  render() {

    dayjs.extend(relativeTime);

    const { milestones, milestone, lastKey, loading } = this.props.data;

    const {title,slug, description,titleImage, body,createdAt,tags, videoEmbed, mainExternalLink, photos} = milestone;

  

   const thisArticleIndex = milestones.findIndex(item => item.slug === milestone.slug);
   const nextArticleIndex = thisArticleIndex + 1 === (milestones.length - 1) ? 0 : thisArticleIndex + 1
   const nextArticle = milestones[nextArticleIndex]

  
    return (
      <div>
        <ResponsiveAppBar/>
        <DocumentMeta {...
         {title: `${title} | Official William White`,
          description: description,
          canonical: `http://officialwilliamwhite.com/milestones/${slug}`,
          meta: {
              charset: 'utf-8',
              name: {
                  keywords: 'react,meta,document,html,tags,william,will,white,tiktok,model'
              }
          }}} />
        <ThemeProvider theme={themeProvider}>
        {((this.state.loadingArticle || loading) && (this.props.data.milestone.slug !== this.props.match.params.slug)) ? (
          <div className='loading-page-container'>
            <CircularProgress/>
          </div>
        ) : (
          <Fade in={true} timeout={1000}>
            <div className="milestone-container">

              
           
                
                  <div className='milestone-title'>
                  <h1>{title}</h1>
                  <div className='side-by-side-2'>                
                      <p className='date'>{dayjs(createdAt).format('MMMM D YYYY')}</p>
                      {tags ? (
                        <div className='tags'>
                      {tags.map((t, i) => 
                      <p className='tag'>{t.toUpperCase()}</p>
                      )}
                      </div>
                      
                      ) : (null)}
                      </div>
                      <div className='milestone-body-container'>

                      <div className='milestone-body'>
              {body}
            </div>

            <div className='side-by-side'>
            { mainExternalLink.length > 0 ? (
              <Button
                  component="a"
                  target= "_blank" 
                  
                  href={mainExternalLink}
                  variant="outlined"
                  color='info'
                  disableElevation
                  >
                    See Full Article
           </Button>
            ) : (null) }
           
           {nextArticle != null ? (<Button
              component={Link}
              to={"/milestones/"+nextArticle.slug}
              variant='outlined'
              color='info'>
                <div className='milestone-button'>
                Next Article
                <ArrowForwardIcon/>
                </div>
              </Button>) : (null) }
              
              </div>



                      
                </div>
                      </div>
<div className='milestone-media'>
                      {videoEmbed.length > 0 ? (
  <iframe width="560" height="315" src={`https://www.youtube.com/embed/${videoEmbed}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
):(null)}

{photos.length > 0 && photos[0].length > 0 ? (<MilestoneGallery photos={photos} />) : (null)}

{((photos.length == 0 || photos[0].length == 0) && videoEmbed.length == 0)  ? (
  <div className='title-image-wrapper'>
  <img src={titleImage}/>
  </div>
) : (null)}

</div>


           
                
            
        </div>
        </Fade>
        
        )}
        <Footer/>
            </ThemeProvider>
      </div>
    )
  }
}

milestone.propTypes = {
  getMilestone: PropTypes.func.isRequired,
  getAllPublishedMilestones: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  data: state.data,
  user: state.user,
  UI: state.UI,
})

export default connect(mapStateToProps, { getMilestone,getAllPublishedMilestones })(withRouter(milestone));

