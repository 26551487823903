import React, { Component } from 'react'
import { Link } from 'react-router-dom';


import '../css/footer.css'

import logo from '../media/william_white_logo_white.png'

export class Footer extends Component {
  render() {
    return (
        <div className='footer-container'>

        <div className='will-footer-logo-container'>
        <img src={logo} className='will-footer-type'/>
        </div>

        
        {/* <p className='link-grid-title'>Company</p> */}
        
        <div className='footer-section'><Link to='/privacy'><p className='link-grid-button'>Privacy Policy</p></Link></div>
        <div className='footer-section'><Link to='/terms'><p className='link-grid-button'>Terms & Conditions</p></Link></div>
        {/* <div className='footer-section'><Link to='/privacy'><p className='link-grid-button'>Cookies</p></Link></div> */}

        
        
        



        <p className='link-grid-button'>Copyright © 2022 William White. All rights reserved.
</p>

    </div>
    )
  }
}

export default Footer