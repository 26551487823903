import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import theme from '../utils/theme';
import '../css/admin.css'

import { getAllMilestones } from '../redux/actions/dataActions';
import { logoutUser } from '../redux/actions/userActions';

import { styled, createTheme, ThemeProvider } from  "@mui/material/styles";

import { CircularProgress, Button, Card, Fade, TextField, Modal, Box } from '@mui/material';
import ResponsiveAppBar from '../components/Appbar';
import Footer from '../components/Footer';
import Moment from 'moment';
import EditMilestone from '../components/EditMilestone';
import NewMilestone from '../components/NewMilestone';


const themeProvider = createTheme(theme);

export class admin extends Component {

    state={
       editOpen:false,
       editMilestone:null,
       newOpen:false
      }

    componentDidMount(){
        document.title = "Admin | Official William White"
        this.props.getAllMilestones();
        window.scrollTo(0, 0);
      }

  
      handleChange = (event) => {
        this.setState({
          [event.target.name]: event.target.value,
        });
      };

      handleEditOpen = (milestone) => {
        this.setState({
          editOpen: true,
          editMilestone: milestone
        });
      };

      handleEditClose = (event) => {
        this.setState({
          editOpen: false,
          editMilestone:null
        });
        this.props.getAllMilestones();
      };

      handleNewOpen = () => {
        this.setState({
          newOpen: true
        });
      };

      handleNewClose = () => {
        this.setState({
          newOpen: false
        });
        this.props.getAllMilestones();
      };

      logout = () => {
        this.props.logoutUser();
      };

  render() {

    const { milestones, loading } = this.props.data;


    return (
      <div>
        <ResponsiveAppBar/>
        <ThemeProvider theme={themeProvider}>
            <div className="admin-container">
            
            <div className='admin-banner'>
           <h1>Admin</h1>
       <Button
       onClick={this.logout}
       variant="outlined"
       >
        Logout
       </Button>
       </div>

{this.state.editMilestone !== null &&
<EditMilestone open={this.state.editOpen} onClose={this.handleEditClose} milestone={this.state.editMilestone}/>
  }
       
      <NewMilestone open={this.state.newOpen} onClose={this.handleNewClose}/>

       { loading ? (
  <div className='loading-container'>
  <CircularProgress/>
  </div>
) : (
<Fade in={true} timeout={1000}>
<div className='admin-milestones-grid-container'>

<Button
      onClick={this.handleNewOpen}
      variant="contained"
      size='large'
      fullWidth
      disableElevation
      >
        Create Milestone
      </Button>

<Button
      onClick={() => this.props.getAllMilestones()}
      variant="outlined"
      size='large'
      fullWidth
      >
        Refresh
      </Button>
                  {milestones.map((e, i) => 
                  <Button
                  onClick={()=>this.handleEditOpen(e)}
                  >
                  <Card elevation={0}>
                    <div className='admin-milestone-item'>
                      <div className='thumbnail-container'>
                      <img src={e.thumbnail}/>
                      
                      </div>
                      <div>
                      <h3 className='title'>{e.title}</h3>
                      <p className='date'>{Moment(e.createdAt).format('MMMM Do YYYY')} </p>
                      <div className='tags'>
                      {e.isPublished ? (<p className='tag'>Published</p>) : (<p className='tag'>Not Published</p>)}
                      {e.tags.map((t, i) => 
                      <p className='tag'>{t.toUpperCase()}</p>
                      )}
                      </div>
                      </div>
                      {/* <p className ='desc'>{e.description}</p> */}
                      
                      
                     
                    </div>
                    </Card>
                    </Button>
                  )}
                

                </div>

                
                </Fade>
)}



           

            


        </div>
        <Footer/>
            </ThemeProvider>
      </div>
    )
  }
}

admin.propTypes = {
  getAllMilestones: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  data: state.data,
  user: state.user,
  UI: state.UI,
})

export default connect(mapStateToProps, { getAllMilestones,logoutUser })(admin);
