import axios from 'axios';
import {
  CLEAR_ERRORS,
  LOADING_UI, LOADING_USER, SET_ADMIN, SET_AUTHENTICATED, SET_CURRENT_USER,
  SET_ERRORS, SET_MESSAGE, SET_UNAUTHENTICATED, SET_USER, STOP_LOADING_UI, SET_UNADMIN
} from '../types';

import { auth } from '../../utils/firebase';

import { browserLocalPersistence, browserSessionPersistence, createUserWithEmailAndPassword, EmailAuthProvider, onAuthStateChanged, reauthenticateWithCredential, sendEmailVerification, sendPasswordResetEmail, setPersistence, signInWithEmailAndPassword, signOut, updateEmail, updatePassword } from "firebase/auth";

import { getUsers } from './dataActions';

import { validateChangeEmail, validateChangePassword, validateChangeUsername, validateLoginData, validateResetPasswordData, validateSignupData } from '../../utils/validation';



axios.defaults.baseURL = '/api';

export const loginUser = (userData, history) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  let {errors , valid} = validateLoginData(userData)
  if (valid) {
    setPersistence(auth, browserLocalPersistence)
    .then(() => {
      return signInWithEmailAndPassword(auth, userData.email,userData.password)
      .then((res) => {
          // dispatch(getUserData()); 
          dispatch({ type: SET_ADMIN })
          dispatch({ type: CLEAR_ERRORS });
          // history.push('/admin');

      })
    })
    .catch((err) => {
          let firebase_errors = {};
      if (err.code === "auth/wrong-password") {
         firebase_errors["general"] ="Wrong credentials, please try again" ;
      } else {
        firebase_errors["general"] = "Something went wrong, please check your credentials and try again";
      }
      dispatch({
        type: SET_ERRORS,
        payload: firebase_errors
      });


    });
    
  } else {
    dispatch({
          type: SET_ERRORS,
          payload: errors
        });
  }
};

export const signupUser = (newUserData, history) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({ type: LOADING_UI });
  
  let {errors , valid} = validateSignupData(newUserData)

  
  axios.get(`/checkUsername/${newUserData.username}`)
  .then((res) => {
    if (res.data.username === newUserData.username){
      valid = false
      errors.username = "This username is already in use"
    } 
  })
.then(() => {

  if (valid) {
    setPersistence(auth, browserLocalPersistence)
    .then(() => {
  createUserWithEmailAndPassword(auth,newUserData.email,newUserData.password)
    .then((data) => {
      newUserData.userId = data.user.uid
      axios
      .post('/join', newUserData)
      .then((res) => {
        dispatch({ type: STOP_LOADING_UI });
        history.push('/');
        sendVerificationLink()
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      })
    })
    .catch((err) => {
      let firebase_errors = {};
      if (err.code === "auth/email-already-in-use") {
       firebase_errors["email"] = "Email is already in use";
      } else if (err.code === "auth/weak-password") {
       firebase_errors["password"] = "Weak password";
      } else {
        firebase_errors["general"] = "Something went wrong, please try again";
      }
      dispatch({
        type: SET_ERRORS,
        payload: firebase_errors
      });
    })
  });
  }
  else {
    dispatch({
          type: SET_ERRORS,
          payload: errors
        });
  }
})
};

export const logoutUser = ( history ) => (dispatch) => {
  // localStorage.removeItem('FBIdToken');
  // delete axios.defaults.headers.common['Authorization'];
  
  return signOut(auth).then(() => {
    // Sign-out successful.
    dispatch({ type: SET_UNAUTHENTICATED });
    dispatch({ type: SET_UNADMIN });
  // history.push('/welcome');
  window.location.href = '/';
  }).catch((error) => {
    // An error happened.
    dispatch({ type: SET_UNAUTHENTICATED });
    dispatch({ type: SET_UNADMIN });
  // history.push('/login');
  window.location.href = '/';
  });
};

export const fetchUser = () => dispatch => {
  // dispatch(logoutUser())
  onAuthStateChanged(auth, user => {
    if (user) {
      return user.getIdToken()
      .then((token) => {
        
        axios.defaults.headers.common['Authorization'] = token;
        dispatch({ type: SET_AUTHENTICATED });
        dispatch({ type: SET_ADMIN });
        // dispatch(getUserData());
        
        return token;
      })
      // .then(() => {
        
      //   return dispatch({
      //     type: SET_CURRENT_USER,
      //     payload: user
      //   });
      // })
    } 
    else {
      // window.location.href = '/welcome';
      dispatch({ type: SET_UNAUTHENTICATED });
      dispatch({ type: SET_UNADMIN });

      dispatch({
        type: SET_CURRENT_USER,
        payload: null
      });
    }
  });
};


export const resetPassword = (email) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  let {errors , valid} = validateResetPasswordData(email)
  if (valid) {
    return sendPasswordResetEmail(auth,email)
    .then(() => {
      dispatch({ type: STOP_LOADING_UI });
      dispatch({
        type: SET_MESSAGE,
        payload: "Reset sent. Please check your email."
      });
    })
  } else {
    dispatch({
          type: SET_ERRORS,
          payload: errors
        });
  }
};

export const sendVerificationLink = () => (dispatch) => {
  dispatch({ type: LOADING_UI });
  // axios.post('/sendVerificationEmail',{email:email})
  sendEmailVerification(auth.currentUser)
  .then(() => {
    dispatch({ type: STOP_LOADING_UI });
    dispatch({
      type: SET_MESSAGE,
      payload: "Verification sent. Please check your email."
    });
  })
  .catch(
    (err) => {
    dispatch({ type: STOP_LOADING_UI })
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    })})
};

export const getUserData = () => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .get('/user')
    .then((res) => {
      dispatch({
        type: SET_USER,
        payload: res.data
      });
      if(res.data.credentials.admin === true){
        dispatch({
          type: SET_ADMIN
        })
      }
    })
    .catch((err) =>  
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    }));
};



export const editUserDetails = (userDetails) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .post('/user', userDetails)
    .then(() => {
      dispatch(getUserData());
    })
    .catch((err) =>  
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    }));
};

export const changeUserEmail = (newEmail) => (dispatch) => {
  // dispatch({ type: LOADING_USER });
    // Verification email sent. 
    // Clicking the link in email will update the email address.
  // })
  let {errors , valid} = validateChangeEmail(newEmail)

  axios.get(`/checkEmail/${newEmail}`)
  .then((res) => {
    if (res.data.email === newEmail){
      valid = false
      errors.email = "This email is already in use"
    } 
  })
  .then (() => { 
  if (valid) {
  
  updateEmail(auth.currentUser, newEmail)
  .then(() => {
    dispatch(sendVerificationLink())
    
      dispatch(fetchUser())
    
      dispatch(editUserDetails({email:newEmail}))
    
      dispatch(getUserData())
    
      dispatch({
        type: SET_MESSAGE,
        payload: "New email updated, check your email for verification."
      })
      // dispatch({ type: STOP_LOADING_UI })
    
}).catch((error) => {
  dispatch({
    type: SET_MESSAGE,
    payload: "There was an issue with changing your email. Please try again another time."
  });
  // dispatch({ type: STOP_LOADING_UI })
  fetchUser()
});
  } else{
    dispatch({
      type: SET_ERRORS,
      payload: errors
    });
    // dispatch({ type: STOP_LOADING_UI })
  }
})
};

export const changeUserPassword = (passwordData) => (dispatch) => {
  const user = auth.currentUser;

  let {errors , valid} = validateChangePassword(passwordData)

  if (valid){
  
  const credential = EmailAuthProvider.credential(
    user.email,
    passwordData.oldPassword
  );
  reauthenticateWithCredential(user,credential)
  .then(function() {
    // User re-authenticated.
    updatePassword(auth.currentUser, passwordData.newPassword).then(() => {
    
      dispatch(fetchUser())
  
    dispatch(getUserData())

         dispatch({
          type: SET_MESSAGE,
          payload: "New Password updated."
        })
        dispatch({ type: CLEAR_ERRORS })

  }).catch((error) => {
    dispatch({
      type: SET_MESSAGE,
      payload: "There was an issue with changing your password. Please try again another time."
    });
    dispatch({ type: CLEAR_ERRORS })
  });
  }).catch((error) =>{
    // An error happened.
    dispatch({
      type: SET_ERRORS,
      payload: {oldPassword:"Incorrect password."}
    });
  });
} else{
  dispatch({
    type: SET_ERRORS,
    payload: errors
  });
}
};

export const changeUserUsername = (usernameData) => (dispatch) => {
  let newUserData = {
    oldUsername:usernameData.oldUsername,
    newUsername:usernameData.newUsername
  }
  let newUserNameResult = "Error"

  let {errors , valid} = validateChangeUsername(newUserData)

  axios.get(`/checkUsername/${newUserData.newUsername}`)
  .then((res) => {
    if (res.data.username === newUserData.newUsername){
      valid = false
      errors.newUsername = "This username is already in use"
    } 
  })
  .then (() => { 
    if (valid){
  axios
  .post(`/user/changeUsername`,newUserData)
    dispatch(fetchUser())
  
    dispatch(getUserData())
  
    dispatch({
      type: SET_MESSAGE,
      payload: "New username updated."
    })
  
    dispatch({ type: STOP_LOADING_UI })
  
    .catch((error) => {
  dispatch({
    type: SET_MESSAGE,
    payload: "There was an issue with changing your username. Please try again another time."
  });
  dispatch({ type: STOP_LOADING_UI })
  fetchUser()
});
  }
  else {
    dispatch({
      type: SET_ERRORS,
      payload: errors
    });
  }
  })

};

export const deleteAccount = (userData) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  const user = auth.currentUser;
  const credential = EmailAuthProvider.credential(
    user.email,
    userData.password
  );
  reauthenticateWithCredential(user,credential).then(function() {
    axios
    .delete(`/user/${userData.username}`)
    .then(() => {
      return dispatch(logoutUser)
    })
    .then(() =>{
      user.delete()
    })
    .catch((err) =>  
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    }));

  }).catch((error) =>{
    // An error happened.
    dispatch({
      type: SET_MESSAGE,
      payload: "There was an issue with authenticating your current password. Please make sure you have provided the correct credentials."
    });
    dispatch({ type: STOP_LOADING_UI })
  });
};




export const deleteUser = (username) => (dispatch) => {
    axios
    .delete(`/users/${username}`)
    .catch((err) => 
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    }));

};

const setAuthorizationHeader = (token) => {
  const FBIdToken = `Bearer ${token}`;
  localStorage.setItem('FBIdToken', FBIdToken);
  axios.defaults.headers.common['Authorization'] = FBIdToken;
};