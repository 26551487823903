import React, { Component } from 'react'
import HomeAppBar from '../components/HomeAppBar'
import DocumentMeta from 'react-document-meta';
import theme from '../utils/theme';
import '../css/home.css'

import video from '../media/willwebsitevid.mp4'

import { styled, createTheme, ThemeProvider } from  "@mui/material/styles";

import AutoPlaySilentVideo from '../components/VideoWrapper';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

import { Fade } from '@mui/material';
import Footer from '../components/Footer';
const themeProvider = createTheme(theme);

const meta = {
  title: 'Home | Official William White',
  description: '',
  canonical: 'http://officialwilliamwhite.com',
  meta: {
      charset: 'utf-8',
      name: {
          keywords: 'react,meta,document,html,tags,home,william,will,white,tiktok,model'
      }
  }
};

export class home extends Component {
  state = {
    muted:true,
    in:false
  }

  constructor(props) {
    super(props);
    this.showScrollAlert = this.showScrollAlert.bind(this);
    
  }

  showScrollAlert(){
    this.setState({in:true})
  }
  componentDidMount(){
    document.title = "Home | Official William White"
    window.scrollTo(0, 0);
    setInterval(this.showScrollAlert, 3000);
  }

  render() {
    return (
      <div>
        <DocumentMeta {...meta} />
        <ThemeProvider theme={themeProvider}>
            
            <div className= "home-banner">
        <Fade direction="up" in={this.state.in} timeout={5000}>
              <div className='home-alert'><KeyboardDoubleArrowDownIcon sx={{opacity:0.5}}/><p><i>Scroll Down</i></p></div>
              </Fade>
            <AutoPlaySilentVideo video={video}/>
            {/* <video autoplay="autoplay" loop="loop" muted defaultMuted playsinline  oncontextmenu="return false;"  preload="auto"  id="myVideo">
        <source src={video} type="video/mp4"/>
        </video> */}
          
        </div>
        
        <HomeAppBar/>
        
<Footer/>
        
            </ThemeProvider>
            </div>
    )
  }
}

export default home