import React, { Component } from 'react'
// import ResponsiveAppBarComingSoon from '../components/HomeAppBar'
import DocumentMeta from 'react-document-meta';
import theme from '../utils/theme';
import '../css/about.css'

import { styled, createTheme, ThemeProvider } from  "@mui/material/styles";

import { Divider, Button, Typography, Fade, TextField, Slide, Grow } from '@mui/material';
import ResponsiveAppBar from '../components/Appbar';
import Footer from '../components/Footer';

import AboutGallery from '../components/AboutGallery.tsx';


const themeProvider = createTheme(theme);

const meta = {
  title: 'About | Official William White',
  description: 'William White ',
  canonical: 'http://officialwilliamwhite.com/about',
  meta: {
      charset: 'utf-8',
      name: {
          keywords: 'react,meta,document,html,tags,about,william,will,white,tiktok,model'
      }
  }
};

export class about extends Component {

    state={
        animationDone: false
      }


    componentDidMount(){
        document.title = "About | Official William White"
        window.scrollTo(0, 0);
      }

      animationDone = () => {
        console.log("ENDED");
        this.setState({animationDone:true})
      };



  render() {
    return (
      <div>
        <ResponsiveAppBar/>
        <DocumentMeta {...meta} />
        <ThemeProvider theme={themeProvider}>
            <div className="about-container">
            <div className="about-banner-container">
              <div className='about-title-container'>
            <h1>William White</h1>
            <img src="https://firebasestorage.googleapis.com/v0/b/william-white-8be23.appspot.com/o/meet-william%2Fwill1.jpg?alt=media&token=3c23e37a-edd5-4e01-9828-1d05e3de3202"/>
            </div>
            <AboutGallery/>
            </div>

            <div className='will-text-stats-container'>

            <div className='about-stats'>
           
            <div className='about-stats-item'>
            <h3>Age</h3>
           <p>23</p>
           </div>

           <div className='about-stats-item'>
           <h3>Birthday</h3>
           <p>February 1, 2000</p>
           </div>

           <div className='about-stats-item'>
           <h3>Hometown</h3>
           <p>Niagara Falls, Ontario, Canada</p>
           </div>

           {/* <div className='about-stats-item'>
           <h3>Representation</h3>
           <p><a href='https://www.modemodels.com/e/2393-william-white/' target="_blank">Mode Models International [Mother Agency]</a>
            <br/><a href='https://www.imgmodels.com/new-york/men-development/7356-william-white' target="_blank">IMG Models Worldwide</a>
            <br/><a href='http://www.wmeagency.com/' target="_blank">WME</a>
            <br/><a href='https://ledrewmodels.com/men/will-white' target="_blank">Ledrew Models Toronto</a></p>
           </div> */}
           
           </div>
       
            <div className='about-text'>
           
           <p><span className='about-span'>William (Will) White</span> is an aspiring actor, model, and content creator from Niagara Falls, Ontario.</p>

           <p>William’s “it factor” and strong features caught the eye of Mode Models International president Kelly Streit in May 2020 on TikTok. Mode signed him, then went on to sign him with LeDrew Models in Toronto.</p>
           
           <p>Inspired by his Dad’s favourite music, Will began creating TikTok’s featuring hit songs from the 1980’s.  His posts went viral leading to a feature in the New York Times and Mode placed him with IMG Models Worldwide and top talent agency William Morris Endeavor.</p>
          
           <p>More press followed for Will, including ET Canada, the Hollywood Reporter, Toronto Star, and the Daily Mirror amongst dozens of other articles, radio and television interviews.</p>
           
           <p>Ralph Lauren booked Will to attend their Ralph’s Club event at the Cannes film festival. He also attended the infamous Cannes AmFAR Gala where he walked in his first fashion show curated by top stylist Carine Roitfeld alongside the world’s top supermodels.</p>
                      
           <p>In support of his relationship with AmFAR, Will’s fans launched a very successful fundraising campaign which raised funds for AIDS research.</p>
           
           <p>William also can be seen in Ralph Lauren’s #Ralphsclub fragrance ad, shot in Cannes, starring Stella Maxwell and Jordan Barrett.</p>
           
           <p>He has gone on to collaborate with top brands including Zara, Hugo Boss, Roots and Hudson’s Bay as well as appearing in national advertising campaigns for Simons and Aldo.</p>
           
           <p>William was asked to surprise superstar Barry Manilow on the internationally syndicated “Kelly Clarkson Show”. William was also named "Sexiest Tiktokker" in People Magazine’s "Sexiest Men Alive" issue, appearing on newsstands around the world.</p>

           <p>William continues to travel and pursue modeling and acting, as well as launching his youtube channel in October of 2022.</p>

           <p>William continues to use his platform for good, supporting various charities from cancer research to children's sports access and various mental health initiatives.</p>

           <p>Keep an eye out for William in future projects as he continues to learn & grow. He’s in it for the #LONGGAME</p>
           

            </div>

            </div>


        </div>
        <Footer/>
            </ThemeProvider>
      </div>
    )
  }
}

export default about